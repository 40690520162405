import React from 'react'
import Img from 'gatsby-image'

import styles from './hero.module.css'

export default ({ data }) => (
  <div className={styles.hero}>
    <div className="imageContainer">
      <Img
      className={styles.heroImage}
      alt={data.name}
      fixed={data.heroImage.fixed}
    />
    </div>
    <div className={styles.heroDetails}>
      <h3 className={styles.heroHeadline}>{data.name}</h3>
      <p className={styles.heroTitle}>{data.title}</p>
      <p className={styles.heroTitle}>
          <a href={"https://github.com/" + data.github}>GitHub</a>/
           <a href={"https://www.linkedin.com/in/" + data.linkedIn}>LinkedIn</a>
      </p>
      <p>{data.shortBio.shortBio}</p>
    </div>
  </div>
)
